import { Form } from 'react-bootstrap'
import React from 'react'
import { formatNumberWithCommas } from '~/utils/formatter'
import { withI18n } from '~/hocs'

const sizes = [24, 60, 120]

const HeaderPagination = ({ t, meta, onChangeFilter, onChangeSorting }: any) => {
  const handleInputChangeFilter = (e: any) => {
    if (onChangeFilter) onChangeFilter(e.target.value)
  }
  const handleInputChangeSorting = (e: any) => {
    if (onChangeFilter) onChangeSorting(e.target.value)
  }
  return (
    <div className="d-flex justify-content-between align-items-center mb-2">
      <h4 className="mb-0">
        <span className="text-secondary" style={{ fontSize: '1rem' }}>全</span>
        <span className="bold">{formatNumberWithCommas(meta.total)}</span>
        <span className="text-secondary" style={{ fontSize: '1rem' }}>件中{meta.to > 0 && <span>/{meta.from}〜 {meta.to}件を表示</span>}</span>
      </h4>
      <div className="d-flex">
        {!meta.search && meta.sort_by !== '-displayed_at' && !meta.top_dl_in_week && <Form.Control
          as="select"
          defaultValue="-download_count"
          autoComplete="off"
          onChange={handleInputChangeSorting}
          className="mr-3"
        >
          <option value="-download_count">関連性の高い順</option>
          <option value="-id">新着順</option>
        </Form.Control>}
        {meta.search && <Form.Control
          as="select"
          defaultValue="-download_count"
          autoComplete="off"
          onChange={handleInputChangeSorting}
          className="mr-3"
        >
          <option value="-download_count">関連性の高い順</option>
          <option value="-id">新着順</option>
        </Form.Control>}
        <Form.Control
          as="select"
          defaultValue={meta.page_size}
          autoComplete="off"
          onChange={handleInputChangeFilter}
        >
          {
            sizes.map(s => <option key={s} value={s}>{t('page_size_postfix', { page_size: s })}</option>)
          }
        </Form.Control>
      </div>

    </div>
  )
}

export default withI18n(HeaderPagination)

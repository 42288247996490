import React, { useState } from 'react'
import SearchPanel, { SearchPanelChoice } from './SearchPanel'
import { withI18n, withUser } from '~/hocs'

import { AppCommonProps } from 'types/app'
import Chip from './Chip'
import SearchService from '~/services/SearchService'
import { Spinner } from 'react-bootstrap'
import { buildQuery } from '~/utils'
import cookie from 'cookie-cutter'
import { routes } from '~/router'
import { useRouter } from 'next/router'

const index = ({ token, t, lng, value = '' }: AppCommonProps) => {
  const searchedCookieKey = 'search'

  const [loading, setLoading] = React.useState(false)
  const [choices, setChoices] = React.useState<Array<SearchPanelChoice>>([])
  const [topChoices, setTopChoices] = React.useState<Array<SearchPanelChoice>>([])
  const [searchedChoices, setSearchedChoices] = React.useState<Array<SearchPanelChoice>>([])
  const [loadingTopChoices, setLoadingTopChoices] = React.useState(false)
  const [searchValue, setSearchValue] = React.useState({
    query: "",
    category: [],
    category_exclude: [],
    mine_type: ""
  })
  const router = useRouter()

  // React.useEffect(() => {
  //   const searched = cookie.get(searchedCookieKey)
  //   if (searched && searched !== '') {
  //     const searchedChoicesCookie = searched.split(',').map((search: string): SearchPanelChoice => ({
  //       key: search,
  //       description: search,
  //     }))
  //     setSearchedChoices(searchedChoicesCookie)
  //   }
  // }, [])

  // React.useEffect(() => {
  //   const cookieCfg = { path: '/' }
  //   cookie.set(searchedCookieKey, searchedChoices.map(c => c.key).join(','), cookieCfg)
  // }, [searchedChoices])

  React.useEffect(() => {
    setSearchValue({
      ...searchValue,
      query: value
    })
  }, [value])

  const handleOnChange = async(event: React.ChangeEvent) => {
    const search: string = event.target.value.trim()
    if (search === '') return

    setLoading(true)
    await SearchService.recommend({ lng, search })
      .then((res: any) => {
        const recommendedChoices = res.data.map((i: any): SearchPanelChoice => {
          const des = '<em>' + i.name.slice(0, search.length) + '</em>' + i.name.slice(search.length, i.name.length)

          return {
            key: i.name,
            description: des,
          }
        })
        setChoices(recommendedChoices)
      })
    setLoading(false)
  }

  const handleOnExpanned = async(isExpanded: boolean) => {
    if (isExpanded && topChoices.length === 0) {
      setLoadingTopChoices(true)
      await SearchService.get({ lng, limit: 10 })
        .then(res => {
          setTopChoices(res.data.map((r: any): SearchPanelChoice => ({
            key: r.term,
            description: r.term,
          })))
        })
      setLoadingTopChoices(false)
    }
  }

  const routeToResultPage = (choice: SearchPanelChoice) => {
    // if (choice.key === searchValue) return
    // SearchService.create(token, { lng, search: choice.key })
    router.push(`${routes.discovery.href}?${buildQuery({
      search: choice.key,
    })}`, undefined, {
      shallow: false,
      locale: lng,
    })
    setSearchValue({
      ...searchValue,
      query: choice.key
    })
  }

  const handleOnSubmit = (choice: SearchPanelChoice, event?: React.MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault()
    // if (searchedChoices.findIndex(c => c.key === choice.key) === -1) {
    //   setSearchedChoices([
    //     {
    //       key: choice.key,
    //       description: choice.key,
    //     },
    //     ...searchedChoices,
    //   ])
    // }
    routeToResultPage(choice)
  }

  const handleRemoveSelectedChoice = (choice: SearchPanelChoice) => {
    setSearchedChoices(searchedChoices.filter(c => c.key !== choice.key))
    // routeToResultPage(choice)
  }

  return (
    <SearchPanel
      className="w-100"
      // onChange={handleOnChange}
      onSubmit={handleOnSubmit}
      // onExpanned={handleOnExpanned}
      isLoading={loading}
      choices={choices}
      topChoices={topChoices}
      searchedChoices={searchedChoices}
      placeholder={t('search_placehoder')}
      value={searchValue.query}
      rounded
    >
      {
        searchedChoices.length > 0 &&
        <div className="chipContainer">
          <div className="bold">Recently searched</div>
          {searchedChoices.map((choice) => (
            <Chip
              deleteLabel={`Delete ${choice.description}`}
              onDelete={() => handleRemoveSelectedChoice(choice)}
              onClick={() => routeToResultPage(choice)}
              key={choice.key}
              value={choice.description}
            />
          ))}
        </div>
      }

      {
        topChoices.length > 0
          ? <div className="chipContainer">
            <div className="bold">Top trending</div>
            {topChoices.map((choice) => (
              <Chip
                key={choice.key}
                value={choice.description}
                onClick={() => handleOnSubmit(choice)}
              />
            ))}
          </div>
          : loadingTopChoices &&
          <div className="flex-center my-2">
            <Spinner as="span" animation="border" size="sm" role="status" />
          </div>
      }

    </SearchPanel>
  )
}

export default withUser(withI18n(index))
